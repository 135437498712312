.container {
    margin-top: 20px;
}

.btn-large,
.btn {
    text-transform: none;
}

.btn-back,
.btn-share,
.btn-delete {
    width: 75%;
}

.fa-list-ul {
    margin-right: 0px;
}

.nav-logo {
    margin-left: 20px;
    height: 50px;
    width: 50px;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid transparent;
    text-align: center;
    font-size: 3rem;
    font-weight: bolder;
    /* line-height: 1.2rem; */
    position: relative;
    top: 10px;
    box-shadow: 5px 5px 10px #333333;
}

.nav-username {
    padding: 0px 15px;
}

.logo-text {
    margin-left: 20px;
}

#login-modal,
#register-modal {
    height: 320px;
}

.share-user {
    margin: 0 8px;
}

.edit-item-modal,
#share-modal {
    height: 350px;
}

#new-list-modal {
    height: 250px;
}

.login-btn,
.register-btn {
    text-align: center;
    margin-bottom: 20px;
}

.login-btn a,
.register-btn a {
    width: 200px;
}

.input-field input[type=text]:focus,
.input-field input[type=password]:focus {
    border-bottom: 2px solid #3f51b5 !important;
    box-shadow: none !important;
}

.fa-file-user,
.fa-sign-out-alt {
    padding-left: 3px !important;
}

.fa-check-square {
    padding-top: 5px;
}

.fa-sort,
.fa-object-group {
    font-size: 1.5rem;
    position: relative;
    top: 3px;
    margin-left: 10px;
}

.shopping-list-btn-div {
    margin: 20px 0px;
    text-align: center;
}

.shopping-list-btn-div a {
    width: 400px;
}

.my-shopping-lists {
    text-align: center;
}

.collection-item .row {
    margin-bottom: 5px;
}

.collapsible-body.saved-items-collapse,
.collapsible-body.departments-collapse {
   padding: 0 !important;
}

.preloader-wrapper {
    margin: auto;
}

.preloader-container {
    display: flex;
    width: 100%;
    height: 500px;
    align-items: center;
}

@media (max-width: 600px) {

    .item-added-by {
        display: none;
    }
}

@media (max-width: 500px) {
    .container {
        /* margin-left: 5px;
        margin-right: 5px; */
        width: 95%;
    }
    .nav-logo {
        display: none;
    }

    .login-btn a,
    .register-btn a {
    width: 100%;
    }

    .btn-back,
    .btn-share,
    .btn-delete {
        width: 100%;
    }
    
    .shopping-list-btn-div a {
        width: 100%;
    }

    .collection-item {
         padding-left: 5px !important;
         padding-right: 5px !important;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4, h5 {
        font-size: 1.3rem;
    }

    .fa-sort,
    .fa-object-group {
        margin-left: 3px;
    }
}
